import message from 'components/utils/message';
import { isEmpty } from 'tools/object';
import request from 'tools/request';
import { mergeDeep } from 'tools/utils';
import 'promise-all-settled-polyfill';
import { getLocalStorage, setLocalStorage } from '../../tools/localStorage';
import router from 'next/router';

export function setOrdersData(payload) {
  return { type: 'ORDERS', payload };
}

export function setOrdersUnpaidData(payload) {
  return { type: 'ORDERS_UNPAID', payload };
}

function ordersIsLoading(payload) {
  return { type: 'ORDERS_IS_LOADING', payload };
}

export const initParams = {
  pageNumber: 1,
  pageSize: 10,
  sortings: { date: -1 },
  populate: [
    { path: 'merchantChildren' },
    // { path: 'bill', select: ['taxable_products', 'taxable_shipping'] },
    // { path: 'stripeProduct', select: 'finalAmount' }
  ],
};

export function getOrders(params, onSuccess, useLoader = true) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    request('/api/orders/filter', {
      params: mergeDeep({ ...initParams }, params),
      useLoader,
    })
      .then((response) => {
        // dispatch(orders(response.data));
        onSuccess(response.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ordersIsLoading(false)));
  };
}

function orderTransformer(data) {
  return data;
  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      product: {
        ...item.product,
        model: item.product.models.find((m) => m.refId === item.stockId),
      },
    })),
  };
}

export function getOrderTotal(_id) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    request(`/api/orders/${_id}/compute/total`)
      .then((response) => dispatch(setOrderTotal(response.data)))
      .catch((err) => console.log(err))
      .finally(() => dispatch(ordersIsLoading(false)));
  };
}

export function getComputeOrderTotal(
  ids = [],
  singleResponse = false,
  voucherCodes = [],
  onSuccess = () => {}
) {
  return (dispatch) => {
    const queryParams = [`singleResponse=${singleResponse}`, `ids=${ids}`];

    if (voucherCodes?.length) {
      queryParams.push(`voucherCodes=${voucherCodes.join(',')}`);
    }
    request(`/api/order/compute/totals?${queryParams.join('&')}`)
      .then((response) => onSuccess(response.data))
      .catch((err) => console.log(err));
  };
}
export function verifyOrderGrowing(
  ids = [],
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(`/api/order/payment/verify?ids=${ids}`)
      .then((response) => onSuccess(response.data))
      .catch(onError);
  };
}
export function retryOrderGrowing(
  id,
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(`/api/order/payment/retry?id=${id}`)
      .then((response) => onSuccess(response.data))
      .catch(onError);
  };
}

export function getOrderPrice(
  country,
  idsQuery,
  carrierId = null,
  voucherCodes = []
) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    const queryParams = [`ids=${idsQuery}`];
    if (carrierId) {
      queryParams.push(`carrierId=${carrierId}`);
    }
    if (voucherCodes?.length) {
      queryParams.push(`voucherCodes=${voucherCodes.join(',')}`);
    }
    request(
      `/api/order/compute/total/country/${country}?${queryParams.join('&')}`,
      {}
    )
      .then((response) => dispatch(setOrderTotal(response.data)))
      .catch((err) => {})
      .finally(() => dispatch(ordersIsLoading(false)));
  };
}

export function getOrderPricePublic(country, idsQuery, carrierId = null) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    const queryParams = [`ids=${idsQuery}`];
    if (carrierId) {
      queryParams.push(`carrierId=${carrierId}`);
    }
    request(`/api/order/compute/total/country/IT?${queryParams.join('&')}`, {})
      .then((response) => dispatch(setOrderTotal(response.data)))
      .catch((err) => {})
      .finally(() => dispatch(ordersIsLoading(false)));
  };
}

function order(payload) {
  return { type: 'ORDER', payload: orderTransformer(payload) };
}

function orderIsLoading(payload) {
  return { type: 'ORDER_IS_LOADING', payload };
}

export function setOrderTotal(payload) {
  return { type: 'ORDER_TOTAL', payload };
}

export function getOrder(id, onComplete = () => {}) {
  return (dispatch) => {
    dispatch(orderIsLoading(true));
    let currentOrder = {};
    request(`/api/orders/${id}`, { useLoader: false })
      .then((response) => {
        currentOrder = response.data;
        dispatch(order(currentOrder));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(orderIsLoading(false));
        onComplete(currentOrder);
      });
  };
}

export function removeOrder(id, onComplete) {
  return (dispatch) => {
    request(`/api/orders/${id}`, {
      method: 'PUT',
      data: {
        status: '2000',
      },
    }).then((response) => {
      onComplete()
    //  dispatch(getOrders({}, (data) => dispatch(setOrdersData(data))));
    });
  };
}

export function removeOrderId(id, onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    request(`/api/orders/${id}`, {
      method: 'PUT',
      data: {
        status: '2000',
      },
    })
      .then((response) => onSuccess(response.data))
      .catch(onError);
  };
}

export function codPayment(
  id,
  onSuccess = () => {},
  onError = () => {},
  onComplete
) {
  return async () => {
    request(`/api/order/pay/cod?orderId=${id}`, {
      method: 'PUT',
    })
      .then(onSuccess)
      .catch(onError)
      .finally(onComplete);
  };
}

export function codConfirm(
  id,
  onSuccess = () => {},
  onError = () => {},
  onComplete
) {
  return async () => {
    request(`/api/order/confirm/cod?orderId=${id}`, {
      method: 'PUT',
    })
      .then(onSuccess)
      .catch(onError)
      .finally(onComplete);
  };
}

export function ecreditPayment(
  ids,
  voucherCodes = [],
  onSuccess = () => {},
  onError = () => {},
  onComplete
) {
  const queryParams = [`orderIds=${ids}`];
  if (voucherCodes?.length) {
    queryParams.push(`voucherCodes=${voucherCodes.join(',')}`);
  }
  return async () => {
    request(`/api/order/pay/ecredit?${queryParams.join('&')}`, {
      method: 'PUT',
    })
      .then(onSuccess)
      .catch(onError)
      .finally(onComplete);
  };
}

export function putOrder(order, action) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    request(`/api/order/${order._id}`, {
      method: 'PUT',
      data: {
        ...order,
      },
    })
      .then((response) => console.log('response', response))
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(ordersIsLoading(false));
        if (action === 'checkout') {
          dispatch(setPaymentOrdersIds([order._id]));
        }
        router.push(action === 'checkout' ? '/update-order' : '/order');
      });
  };
}

export function orderProducts(products) {
  return {
    type: 'ORDER_PRODUCTS',
    payload: products,
  };
}

function orderRecipient(recipient) {
  return {
    type: 'ORDER_RECIPIENT',
    payload: recipient,
  };
}

function orderStep(step = 1) {
  return {
    type: 'ORDER_STEP',
    payload: step,
  };
}

function orderBill(bill) {
  return {
    type: 'ORDER_BILL',
    payload: bill,
  };
}

function orderShipping(shipping) {
  return {
    type: 'ORDER_SHIPPING',
    payload: shipping,
  };
}

export function orderPayment(payment) {
  return {
    type: 'ORDER_PAYMENT',
    payload: payment,
  };
}

export function confirmOrders(orders, onComplete) {
  return (dispatch) => {
    request(`/api/ghost/order/confirm`, { method: 'POST', data: orders })
      .then((response) => {
        onComplete();
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
}

export function forceReturnOrder(orderId, onComplete) {
  return (dispatch) => {
    request(`/api/return_order/order/${orderId}`, {
      method: 'POST',
      data: { orderId },
    })
      .then((response) => {
        const data = response.data;
        onComplete(data);
      })
      .catch((err) => console.log(err));
  };
}

export function getOrdersByIds(ids) {
  return (dispatch) => {
    dispatch(ordersIsLoading(true));
    Promise.allSettled(
      ids.map((id) => request(`/api/order/${id}`, { params: initParams }))
    )
      .then((response) => {
        dispatch(setOrdersData(response.map((r) => r?.value?.data)));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(orderIsLoading(false)));
  };
}

export function setLocalStorageIds() {
  return (dispatch) => {
    const ids = getLocalStorage('idsPayment');
    dispatch(orderPayment(JSON.parse(ids)));
  };
}

function orderNote(note) {
  return {
    type: 'ORDER_NOTE',
    payload: note,
  };
}

export function addOrderProduct(product) {
  return (dispatch, getState) => {
    const products = getState()['orderProducts'];

    const index = products.findIndex((el) => el.model.id === product.model.id);

    if (index >= 0) {
      const quantity = products[index].quantity + product.quantity;
      if (quantity > product.model.availability) {
        message.warning('Inventory is not enough');
      } else {
        products[index].quantity = quantity;
        dispatch(orderProducts([...products]));
      }
    } else {
      dispatch(orderProducts([...products, product]));
    }
  };
}

export function removeOrderProduct(product) {
  return (dispatch, getState) => {
    const newProducts = getState()['orderProducts'].filter(
      (el) => el.id !== product.id
    );
    dispatch(orderProducts(newProducts));

    if (isEmpty(newProducts)) {
      dispatch(setOrderStep());
    }
  };
}

export function setOrderRecipient(recipient) {
  return (dispatch) => {
    dispatch(orderRecipient(recipient));
  };
}

export function setOrderStep(step) {
  return (dispatch) => {
    dispatch(orderStep(step));
  };
}

export function setOrderBill(bill) {
  return (dispatch) => {
    dispatch(orderBill(bill));
  };
}

export function setOrderShipping(shipping) {
  return (dispatch) => dispatch(orderShipping(shipping));
}

export function setOrderNote(note) {
  return (dispatch) => dispatch(orderNote(note));
}

export function clearOrderSteps() {
  return (dispatch) => {
    dispatch(orderProducts([]));
    dispatch(orderRecipient({}));
    dispatch(orderBill({}));
    dispatch(orderStep(1));
    dispatch(orderShipping(null));
    dispatch(orderNote(''));
  };
}

export function setPaymentOrdersIds(ids) {
  return (dispatch) => {
    dispatch(orderPayment(ids));
    setLocalStorage('idsPayment', JSON.stringify(ids));
  };
}
